// produces a string like "GMT +01:00" or "GMT" or "GMT -01:00" based on the
// offset, which is produced by getTimezoneOffset of package
// 'get-timezone-offset' (so in minutes and with opposite sign to that of

import { DAY_IN_MILLISECONDS, MINUTE } from '@common/definitions';

// the string produced by this function
export const getGMTOffsetLabel = (offsetMinutes: number) => {
  if (offsetMinutes === 0) {
    return 'GMT';
  }
  const sign = offsetMinutes < 0 ? '+' : '-';
  const offsetMinutesAbs = Math.abs(offsetMinutes);
  const hours = Math.floor(offsetMinutesAbs / MINUTE);
  const minutes = offsetMinutesAbs % MINUTE;
  const formattedHours = `${hours < 10 ? '0' : ''}${hours}`;
  const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}`;
  return `GMT ${sign}${formattedHours}:${formattedMinutes}`;
};

export function getStartOfDay(day: string | number | Date) {
  return (new Date(day)).setHours(0, 0, 0, 0);
}

export function getDaysLater(daysN: number) {
  return new Date(Date.now() + DAY_IN_MILLISECONDS * daysN);
}

export function isDistantInDays(date: Date, daysDistance: number) {
  return getStartOfDay(date) === getStartOfDay(getDaysLater(daysDistance));
}

export const timeStrToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':');
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
};

export const isInPast = (date: Date) => date < new Date();
export const isToday = (date: Date) => isDistantInDays(date, 0);
export const isTomorrow = (date: Date) => isDistantInDays(date, 1);

// used to check if a date is within a certain days frame from today
// isWithinDays(new Date(2024, 0, 1), 4, 10) would return true if the date is
// between 4 and 10 days from now()
export function isWithinDays(date: Date, endDay: number, startDay: number = 0) {
  return (
    getStartOfDay(date) >= getStartOfDay(getDaysLater(startDay)) &&
    getStartOfDay(date) < getStartOfDay(getDaysLater(endDay))
  );
}
